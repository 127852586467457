import { showMessage } from '~/store/index'

export function dispatchMessage(dispatch, content, color) {
  return dispatch(
    showMessage,
    {
      content,
      color,
    },
    {
      root: true,
    }
  )
}

export function dispatchSuccessMessage(dispatch, content) {
  return dispatchMessage(dispatch, content, 'success lighten-1')
}

export function dispatchErrorMessage(dispatch, content) {
  return dispatchMessage(dispatch, content, 'error lighten-1')
}

export function dispatchInfoMessage(dispatch, content) {
  return dispatchMessage(dispatch, content, 'primary lighten-1')
}
