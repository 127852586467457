//
//
//
//
//
//
//
//
//
//
//
//

import globalLoader from '~/mixins/globalLoader'

export default {
  name: 'ErrorLayout',
  mixins: [globalLoader],
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred',
      defaultNuxtLoader: true,
    }
  },
  head() {
    const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title,
    }
  },
}
