const MIN_TOKEN_VALIDITY_SECONDS = 30
export const QUEUE_SESSION_ID = crypto.randomUUID()

export default function ({ $axios, $keycloak, redirect }) {
  $axios.onRequest((config) => {
    if (config.baseURL.includes('localhost') || config.baseURL.includes('qispace.com')) {
      config.withCredentials = true
    }
    if (config.data instanceof FormData) {
      if (!config.headers) {
        config.headers = {}
      }
      config.headers['Content-Type'] = 'multipart/form-data'
    }
    return config
  })

  $axios.interceptors.request.use((config) => {
    if ($keycloak.authenticated) {
      config.headers.Authorization = 'Bearer ' + $keycloak.token
    }
    config.headers['x-queue-session-token'] = QUEUE_SESSION_ID
    return config
  })

  $axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config
      const code = parseInt(error.response && error.response.status)
      if (code === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        const refreshed = await $keycloak.updateToken(MIN_TOKEN_VALIDITY_SECONDS)
        if (refreshed) {
          return $axios(originalRequest)
        } else {
          console.log('Failed to refresh the token.')
          redirect('/404')
        }
      } else {
        return error.response
      }
    }
  )
}
