import { simpleGetters, simpleMutations, actionCreator } from '@qispace/vue-components'
import { API_URL } from '~/constants/env'

export const contacts = 'contacts'
export const loading = 'loading'
export const error = 'error'

export const createContact = 'createContact'
export const updateContact = 'updateContact'
export const deleteContact = 'deleteContact'
export const moveContact = 'moveContact'

export const state = () => ({
  [loading]: false,
  [error]: undefined,
})

export const getters = {
  ...simpleGetters(state),
}

export const mutations = {
  ...simpleMutations(state),
}

export const actions = {
  [createContact](storeBag, { slug, payload }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      fetch: () => this.$axios.post(`${API_URL}/projects/${slug}/contacts`, payload),
    })
  },
  [updateContact](storeBag, { slug, id, payload }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      fetch: () => this.$axios.put(`${API_URL}/projects/${slug}/contacts/${id}`, payload),
    })
  },
  [deleteContact](storeBag, { slug, id }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      successMsg: this.$i18n.t('project-contacts-updated'),
      failureMsg: this.$i18n.t('could-not-update-project-contacts'),
      fetch: () => this.$axios.delete(`${API_URL}/projects/${slug}/contacts/${id}`),
    })
  },
  [moveContact](storeBag, { slug, id, up }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      successMsg: this.$i18n.t('project-contacts-moved'),
      failureMsg: this.$i18n.t('could-not-moved-project-contacts'),
      fetch: () =>
        this.$axios.get(`${API_URL}/projects/${slug}/contacts/${id}/order/${up ? 'up' : 'down'}`),
    })
  },
}
