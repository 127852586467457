import { simpleGetters, simpleMutations, actionCreator } from '@qispace/vue-components'
import { API_URL } from '~/constants/env'

export const buildings = 'buildings'
export const building = 'building'
export const loading = 'loading'
export const error = 'error'

export const fetchBuildings = 'fetchBuildings'
export const fetchBuilding = 'fetchBuilding'
export const createBuilding = 'createBuilding'
export const updateBuilding = 'updateBuilding'
export const deleteBuilding = 'deleteBuilding'

export const state = () => ({
  [buildings]: undefined,
  [building]: undefined,
  [loading]: false,
  [error]: undefined,
})

export const getters = {
  ...simpleGetters(state),
}

export const mutations = {
  ...simpleMutations(state),
}

export const actions = {
  [fetchBuildings](storeBag, { slug }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: buildings,
      fetch: () => this.$axios.get(`${API_URL}/projects/${slug}/buildings`),
    })
  },
  [fetchBuilding](storeBag, { id }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: building,
      fetch: () => this.$axios.get(`${API_URL}/buildings/${id}`),
    })
  },
  [createBuilding](storeBag, { payload }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: building,
      successMsg: this.$i18n.t('created-building'),
      failureMsg: this.$i18n.t('could-not-create-building'),
      fetch: () => this.$axios.post(`${API_URL}/buildings`, payload),
    })
  },
  [updateBuilding](storeBag, { id, payload }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: building,
      successMsg: this.$i18n.t('updated-building'),
      failureMsg: this.$i18n.t('could-not-update-building'),
      fetch: () => this.$axios.put(`${API_URL}/buildings/${id}`, payload),
    })
  },
  [deleteBuilding](storeBag, { id }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      successMsg: this.$i18n.t('deleted-building'),
      failureMsg: this.$i18n.t('could-not-delete-building'),
      fetch: () => this.$axios.delete(`${API_URL}/buildings/${id}`),
    })
  },
}
