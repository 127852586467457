import Vue from 'vue'
import QiSpaceVueComponents from '@qispace/vue-components'
import { dispatchSuccessMessage, dispatchErrorMessage, dispatchInfoMessage } from '@/utils/store'

Vue.use(QiSpaceVueComponents, {
  dispatchSuccessMessage,
  dispatchErrorMessage,
  dispatchInfoMessage,
  useBlotFormatter: true,
})
