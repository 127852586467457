import { simpleGetters, simpleMutations, actionCreator } from '@qispace/vue-components'
import { API_URL } from '~/constants/env'

export const imports = 'imports'
export const data = 'data'
export const dryDiff = 'dryDiff'
export const diff = 'diff'
export const csv = 'csv'
export const loading = 'loading'
export const error = 'error'

export const readFromCsv = 'readFromCsv'
export const convertCsvToJson = 'convertCsvToJson'
export const writeFromJson = 'writeFromJson'
export const reset = 'reset'

export const state = () => ({
  [csv]: undefined,
  [data]: undefined,
  [dryDiff]: undefined,
  [diff]: undefined,
  [loading]: false,
  [error]: undefined,
})

export const getters = {
  ...simpleGetters(state),
}

export const mutations = {
  ...simpleMutations(state),
  [reset](state) {
    state.csv = undefined
    state.data = undefined
    state.dryDiff = undefined
    state.diff = undefined
    state.error = undefined
  },
}

export const actions = {
  [readFromCsv](storeBag, payload) {
    const { slug, file, newline, separator } = payload
    const formData = new FormData()
    formData.set('data', file)
    if (newline) {
      formData.set('newline', newline)
    }
    if (separator) {
      formData.set('separator', separator)
    }
    return actionCreator({
      storeBag,
      loading,
      error,
      state: csv,
      successMsg: this.$i18n.t('imported-columns'),
      failureMsg: this.$i18n.t('could-not-import-columns'),
      fetch: () => this.$axios.put(`${API_URL}/projects/${slug}/import/read-from-csv`, formData),
    })
  },
  [convertCsvToJson](storeBag, payload) {
    const { slug, data, mappings, noNotification } = payload
    return actionCreator({
      storeBag,
      loading,
      error,
      state: 'data',
      successMsg: noNotification ? undefined : this.$i18n.t('converted-data'),
      failureMsg: this.$i18n.t('could-not-convert-data'),
      fetch: () =>
        this.$axios.put(`${API_URL}/projects/${slug}/import/convert-csv-to-json`, {
          data,
          mappings,
        }),
    })
  },
  [writeFromJson](storeBag, payload) {
    const { slug, data, noNotification, commit } = payload
    return actionCreator({
      storeBag,
      loading,
      error,
      state: commit ? diff : dryDiff,
      successMsg: noNotification
        ? undefined
        : commit
        ? this.$i18n.t('dry-imported-data')
        : this.$i18n.t('imported-data'),
      failureMsg: this.$i18n.t('could-not-import-data'),
      fetch: () =>
        this.$axios.post(
          `${API_URL}/projects/${slug}/import/write-from-json${commit ? '?commit=true' : ''}`,
          data
        ),
    })
  },
  [reset]({ commit }) {
    commit(reset)
  },
}
