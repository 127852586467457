//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import Stomp from 'stomp-websocket'
import globalLoader from '~/mixins/globalLoader'
import { global, initialized, projectContext, initializeGlobalStore } from '~/store/global'
import {
  redirected,
  showNavPanel,
  setRedirected,
  toggleExpandNavPanel,
  showMessage,
} from '~/store/index'
import app from '~/mixins/app'
import { QUEUE_SESSION_ID } from '~/plugins/axios'

export default {
  name: 'Layout',
  mixins: [globalLoader, app],
  data() {
    return {
      stomp: null,
      unitSubscription: null,
      buildingSubscription: null,
      projectSubscription: null,
      projectCacheClearStartSubscription: null,
      projectCacheClearFinishedSubscription: null,
    }
  },
  computed: {
    ...mapGetters(global, [initialized, projectContext]),
    ...mapGetters([showNavPanel, redirected]),
    isMobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
    },
  },
  watch: {
    projectContext: {
      handler(_, old) {
        if (this.projectContext && (!old || this.projectContext.id !== old.id)) {
          if (this.stomp) {
            this.stomp.disconnect()
          }
          this.connectToRabbitMq(this.projectContext.id)
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.initializeGlobalStore()
    await this.setRedirected(true)
  },
  beforeDestroy() {
    if (this.stomp) {
      this.unitSubscription.unsubscribe()
      this.buildingSubscription.unsubscribe()
      this.projectSubscription.unsubscribe()
      this.projectCacheClearStartSubscription.unsubscribe()
      this.projectCacheClearFinishedSubscription.unsubscribe()
      this.stomp.disconnect()
    }
  },
  methods: {
    ...mapActions([showMessage]),
    ...mapActions(global, [initializeGlobalStore]),
    ...mapActions([toggleExpandNavPanel]),
    ...mapActions([setRedirected]),
    connectToRabbitMq(projectContext) {
      this.stomp = Stomp.over(new WebSocket(this.$config.rmq.stompUrl))
      const onConnect = () => {
        this.unitSubscription = this.stomp.subscribe(
          `/exchange/ex.api.public/api.projects.${projectContext}.buildings.*.units.*.*`,
          (msg) => {
            if (this.isMsgFromSomeoneElse(msg)) {
              const body = JSON.parse(msg.body)
              this.showMessage({
                content: this.$t('remote-unit-updated', {
                  unit: body.unit,
                }),
                color: 'warning',
              })
            }
          }
        )
        this.buildingSubscription = this.stomp.subscribe(
          `/exchange/ex.api.public/api.projects.${projectContext}.buildings.*`,
          (msg) => {
            if (this.isMsgFromSomeoneElse(msg)) {
              const body = JSON.parse(msg.body)
              this.showMessage({
                content: this.$t('remote-building-updated', {
                  building: body.building,
                }),
                color: 'warning',
              })
            }
          }
        )
        this.projectSubscription = this.stomp.subscribe(
          `/exchange/ex.api.public/api.projects.${projectContext}.*`,
          (msg) => {
            if (this.isMsgFromSomeoneElse(msg)) {
              this.showMessage({
                content: this.$t('remote-project-updated'),
                color: 'warning',
              })
            }
          }
        )
        this.projectCacheClearStartSubscription = this.stomp.subscribe(
          `/exchange/ex.api.public/api.projects.${projectContext}.cache.started`,
          () => {
            this.showMessage({
              content: this.$t('project-cache-is-being-cleared'),
              color: 'warning',
            })
          }
        )
        this.projectCacheClearFinishedSubscription = this.stomp.subscribe(
          `/exchange/ex.api.public/api.projects.${projectContext}.cache.finished`,
          () => {
            this.showMessage({
              content: this.$t('project-cache-has-been-cleared'),
              color: 'success',
            })
          }
        )
      }
      const onError = (e) => {
        this.connectToRabbitMq(projectContext)
      }
      this.stomp.connect(
        this.$config.rmq.user,
        this.$config.rmq.pass,
        onConnect,
        onError,
        this.$config.rmq.vhost
      )
      if (!this.$config.baseApiUrl.includes('localhost')) {
        this.stomp.debug = null
      }
    },
    isMsgFromSomeoneElse(msg) {
      return (
        'x-queue-session-token' in msg.headers &&
        msg.headers['x-queue-session-token'] !== QUEUE_SESSION_ID
      )
    },
  },
}
