import { simpleGetters, simpleMutations, actionCreator } from '@qispace/vue-components'
import { API_URL } from '~/constants/env'

export const projects = 'projects'
export const project = 'project'
export const projectPlayerdata = 'projectPlayerdata'
export const projectUnitsPlayerdata = 'projectUnitsPlayerdata'
export const loading = 'loading'
export const loadingPlayerdata = 'loadingPlayerdata'
export const error = 'error'

export const fetchProjects = 'fetchProjects'
export const fetchProject = 'fetchProject'
export const fetchProjectPlayerdata = 'fetchProjectPlayerdata'
export const fetchProjectUnitsPlayerdata = 'fetchProjectUnitsPlayerdata'

export const createProject = 'createProject'
export const updateProject = 'updateProject'
export const resetPlayerdata = 'resetPlayerdata'

export const state = () => ({
  [projects]: undefined,
  [project]: undefined,
  [projectPlayerdata]: undefined,
  [projectUnitsPlayerdata]: undefined,
  [loading]: false,
  [loadingPlayerdata]: false,
  [error]: undefined,
})

export const getters = {
  ...simpleGetters(state),
}

export const mutations = {
  ...simpleMutations(state),
}

export const actions = {
  [fetchProjects](storeBag, { slug }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: projects,
      fetch: () => this.$axios.get(`${API_URL}/clients/${slug}/projects`),
    })
  },
  [fetchProject](storeBag, { slug }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: project,
      fetch: () => this.$axios.get(`${API_URL}/projects/${slug}`),
    })
  },
  [fetchProjectPlayerdata](storeBag, { slug }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: projectPlayerdata,
      fetch: () => this.$axios.get(`${API_URL}/playerdata/${slug}`),
    })
  },
  [fetchProjectUnitsPlayerdata](storeBag, { slug }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: projectUnitsPlayerdata,
      fetch: () => this.$axios.get(`${API_URL}/playerdata/${slug}/units`),
    })
  },
  [createProject](storeBag, { payload }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: project,
      successMsg: this.$i18n.t('created-project'),
      failureMsg: this.$i18n.t('could-not-create-project'),
      fetch: () => this.$axios.post(`${API_URL}/projects`, payload),
    })
  },
  [resetPlayerdata](storeBag, { slug }) {
    return actionCreator({
      storeBag,
      loading: loadingPlayerdata,
      error,
      commit: projectUnitsPlayerdata,
      successMsg: this.$i18n.t('updated-project-playerdata-with-all-units'),
      failureMsg: this.$i18n.t('could-not-update-project-playerdata'),
      fetch: () => this.$axios.post(`${API_URL}/playerdata/${slug}/units`),
    })
  },
  [updateProject](storeBag, { slug, payload }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: project,
      successMsg: this.$i18n.t('updated-project'),
      failureMsg: this.$i18n.t('could-not-update-project'),
      fetch: () => this.$axios.put(`${API_URL}/projects/${slug}`, payload),
    })
  },
}
