import { simpleGetters, simpleMutations, actionCreator } from '@qispace/vue-components'
import { API_URL } from '~/constants/env'

export const validations = 'validations'
export const loading = 'loading'
export const error = 'error'

export const fetchProjectValidations = 'fetchProjectValidations'

export const state = () => ({
  [validations]: undefined,
  [loading]: false,
  [error]: undefined,
})

export const getters = {
  ...simpleGetters(state),
}

export const mutations = {
  ...simpleMutations(state),
}

export const actions = {
  [fetchProjectValidations](storeBag, { slug, force }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: validations,
      fetch: () =>
        this.$axios.get(`${API_URL}/projects/${slug}/validations${force ? '?force' : ''}`),
    })
  },
}
