import { simpleGetters, simpleMutations, actionCreator } from '@qispace/vue-components'
import { API_URL } from '~/constants/env'

export const clientMetrics = 'clientMetrics'
export const loading = 'loading'
export const error = 'error'

export const getClientMetrics = 'getClientMetrics'

export const state = () => ({
  [clientMetrics]: undefined,
  [loading]: false,
  [error]: undefined,
})

export const getters = {
  ...simpleGetters(state),
}

export const mutations = {
  ...simpleMutations(state),
}

export const actions = {
  [getClientMetrics](storeBag) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: clientMetrics,
      fetch: () => this.$axios.get(`${API_URL}/clients/metrics`),
    })
  },
}
