import { simpleGetters, simpleMutations, actionCreator } from '@qispace/vue-components'
import { API_URL } from '~/constants/env'

export const projectVersions = 'projectVersions'
export const loading = 'loading'
export const error = 'error'

export const fetchProjectVersions = 'fetchProjectVersions'

export const state = () => ({
  [projectVersions]: undefined,
  [loading]: false,
  [error]: undefined,
})

export const getters = {
  ...simpleGetters(state),
}

export const mutations = {
  ...simpleMutations(state),
}

export const actions = {
  [fetchProjectVersions](storeBag, { projectId }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: projectVersions,
      fetch: () => this.$axios.get(`${API_URL}/projects/versions/project/${projectId}`),
    })
  },
}
