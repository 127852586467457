import { simpleGetters, simpleMutations, actionCreator } from '@qispace/vue-components'
import { API_URL } from '~/constants/env'

export const fields = 'fields'
export const loading = 'loading'
export const error = 'error'

export const createField = 'createField'
export const updateField = 'updateField'
export const deleteField = 'deleteField'

export const state = () => ({
  [loading]: false,
  [error]: undefined,
})

export const getters = {
  ...simpleGetters(state),
}

export const mutations = {
  ...simpleMutations(state),
}

export const actions = {
  [createField](storeBag, { slug, payload }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      successMsg: this.$i18n.t('created-field'),
      failureMsg: this.$i18n.t('could-not-create-field'),
      fetch: () => this.$axios.post(`${API_URL}/projects/${slug}/fields`, payload),
    })
  },
  [updateField](storeBag, { slug, id, payload }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      successMsg: this.$i18n.t('updated-field'),
      failureMsg: this.$i18n.t('could-not-update-field'),
      fetch: () => this.$axios.put(`${API_URL}/projects/${slug}/fields/${id}`, payload),
    })
  },
  [deleteField](storeBag, { slug, id }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      successMsg: this.$i18n.t('deleted-field'),
      failureMsg: this.$i18n.t('could-not-delete-field'),
      fetch: () => this.$axios.delete(`${API_URL}/projects/${slug}/fields/${id}`),
    })
  },
}
