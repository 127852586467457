import { simpleGetters, simpleMutations, actionCreator } from '@qispace/vue-components'
import { API_URL } from '~/constants/env'

export const renderData = 'renderData'
export const loading = 'loading'
export const error = 'error'

export const fetchRenderData = 'fetchRenderData'
export const updateRenderData = 'updateRenderData'

export const state = () => ({
  [renderData]: undefined,
  [loading]: false,
  [error]: undefined,
})

export const getters = {
  ...simpleGetters(state),
}

export const mutations = {
  ...simpleMutations(state),
}

export const actions = {
  [fetchRenderData](storeBag, { slug }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: renderData,
      fetch: () => this.$axios.get(`${API_URL}/projects/${slug}/render-data`),
    })
  },

  [updateRenderData](storeBag, { slug, payload }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: renderData,
      successMsg: this.$i18n.t('updated-render-data'),
      failureMsg: this.$i18n.t('could-not-update-render-data'),
      fetch: () => this.$axios.put(`${API_URL}/projects/${slug}/render-data`, payload),
    })
  },
}
