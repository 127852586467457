import { simpleGetters, simpleMutations, actionCreator } from '@qispace/vue-components'
import { API_URL } from '~/constants/env'

export const clients = 'clients'
export const client = 'client'
export const loading = 'loading'
export const error = 'error'

export const fetchClients = 'fetchClients'
export const createClient = 'createClient'
export const updateClient = 'updateClient'

export const state = () => ({
  [clients]: undefined,
  [client]: undefined,
  [loading]: false,
  [error]: undefined,
})

export const getters = {
  ...simpleGetters(state),
}

export const mutations = {
  ...simpleMutations(state),
}

export const actions = {
  [fetchClients](storeBag) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: clients,
      fetch: () => this.$axios.get(`${API_URL}/clients`),
    })
  },
  [createClient](storeBag, { payload }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: client,
      successMsg: this.$i18n.t('created-client'),
      failureMsg: this.$i18n.t('could-not-create-client'),
      fetch: () => this.$axios.post(`${API_URL}/clients`, payload),
    })
  },
  [updateClient](storeBag, { slug, payload }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: client,
      successMsg: this.$i18n.t('updated-client'),
      failureMsg: this.$i18n.t('could-not-update-client'),
      fetch: () => this.$axios.put(`${API_URL}/clients/${slug}`, payload),
    })
  },
}
