import { simpleGetters, simpleMutations, actionCreator } from '@qispace/vue-components'
import { API_URL } from '~/constants/env'

export const users = 'users'
export const user = 'user'
export const roles = 'roles'

export const loading = 'loading'
export const error = 'error'

export const fetchUsers = 'fetchUsers'
export const fetchUser = 'fetchUser'
export const createUser = 'createUser'
export const updateUser = 'updateUser'
export const deleteUser = 'deleteUser'

export const fetchRoles = 'fetchRoles'

export const state = () => ({
  users: [],
  user: undefined,
  loading: false,
  error: undefined,
})

export const getters = {
  ...simpleGetters(state),
}

export const mutations = {
  ...simpleMutations(state),
}

export const actions = {
  [fetchUsers](storeBag) {
    return actionCreator({
      storeBag,
      loading,
      error,
      state: users,
      fetch: () => this.$axios.get(`${API_URL}/secure/users`),
    })
  },
  [fetchUser](storeBag, id) {
    return actionCreator({
      storeBag,
      loading,
      error,
      state: user,
      fetch: () => this.$axios.get(`${API_URL}/secure/users/${id}`),
    })
  },
  [createUser](storeBag, payload) {
    return actionCreator({
      storeBag,
      loading,
      error,
      state: user,
      fetch: () => this.$axios.post(`${API_URL}/secure/users`, payload),
      successMsg: this.$i18n.t('user-created'),
      errorMsg: this.$i18n.t('could-not-create-user'),
    })
  },
  [updateUser](storeBag, payload) {
    const { id, ...rest } = payload
    return actionCreator({
      storeBag,
      loading,
      error,
      state: user,
      fetch: () => this.$axios.put(`${API_URL}/secure/users/${id}`, rest),
      successMsg: this.$i18n.t('user-updated'),
      errorMsg: this.$i18n.t('could-not-update-user'),
    })
  },
  [deleteUser](storeBag, id) {
    return actionCreator({
      storeBag,
      loading,
      error,
      fetch: () => this.$axios.delete(`${API_URL}/secure/users/${id}`),
      successMsg: this.$i18n.t('user-deleted'),
      errorMsg: this.$i18n.t('could-not-delete-user'),
    })
  },
  [fetchRoles](storeBag) {
    return actionCreator({
      storeBag,
      loading,
      error,
      state: roles,
      fetch: () => this.$axios.get(`${API_URL}/secure/users/roles`),
    })
  },
}
