//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    outlined: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    switchLanguage(locale) {
      this.$i18n.setLocale(locale.code)
      window.location.reload()
    },
  },
}
