//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Loader',
  props: {
    color: {
      type: String,
      default: () => 'primary',
    },
    small: {
      type: Boolean,
      default: () => false,
    },
    cls: {
      type: String,
      default: () => 'pa-4 text-center',
    },
  },
}
