import {
  simpleGetters,
  simpleMutations,
  actionCreator,
  objectToQueryParameters,
} from '@qispace/vue-components'
import { API_URL } from '~/constants/env'

export const analytics = 'analytics'
export const loading = 'loading'
export const error = 'error'

export const fetchAnalytics = 'fetchAnalytics'

function formatValue(value, decimalPlaces = 3) {
  return parseFloat(value).toFixed(decimalPlaces)
}

function formatPercentage(value) {
  const percentValue = parseFloat(value) * 100
  return percentValue.toFixed(2) + '%'
}

const prepareTableData = (data) => {
  const tableData = []
  for (const row of data) {
    tableData.push({
      projectId: row.project ? row.project.id : undefined,
      project: row.project ? row.project.name : '',
      building: row.building ? row.building.name : '',
      unitId: row.unit ? row.unit.unitId : '',
      unitTitle: row.unit ? row.unit.title : '',
      route: row.dimensionValues[0].value,
      activeUsers: row.metricValues[0].value,
      averageSessionDuration: formatValue(row.metricValues[1].value),
      engagedSessions: row.metricValues[2].value,
      engagementRate: formatPercentage(row.metricValues[3].value),
      newUsers: row.metricValues[4].value,
      screenPageViews: row.metricValues[5].value,
      screenPageViewsPerSession: formatValue(row.metricValues[6].value),
      sessions: row.metricValues[7].value,
      sessionsPerUser: formatValue(row.metricValues[8].value),
      userEngagementDuration: row.metricValues[9].value,
    })
  }
  return tableData
}

export const state = () => ({
  [analytics]: undefined,
  [loading]: false,
  [error]: undefined,
})

export const getters = {
  ...simpleGetters(state),
}

export const mutations = {
  ...simpleMutations(state),
}

export const actions = {
  [fetchAnalytics](storeBag, { slug, query }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      state: analytics,
      action: async () => {
        const analytics = await this.$axios.get(
          `${API_URL}/projects/${slug}/analytics?${query ? objectToQueryParameters(query) : ''}`
        )
        return {
          unitsData: prepareTableData(analytics.data.unitsData),
          apartmentChooserData: prepareTableData(analytics.data.apartmentChooserData),
          buildingChooserData: prepareTableData(analytics.data.buildingChooserData),
        }
      },
    })
  },
}
