import { simpleGetters, simpleMutations, actionCreator } from '@qispace/vue-components'
import { API_URL } from '~/constants/env'

export const units = 'units'
export const unit = 'unit'
export const unitPlayerdata = 'unitPlayerdata'
export const loading = 'loading'
export const pdfLoading = 'pdfLoading'
export const error = 'error'

export const unitImagesLoading = 'unitImagesLoading'
export const unitImages = 'unitImages'

export const fetchUnits = 'fetchUnits'
export const fetchUnit = 'fetchUnit'
export const fetchUnitPlayerdata = 'fetchUnitPlayerdata'
export const createUnit = 'createUnit'
export const updateUnit = 'updateUnit'
export const deleteUnit = 'deleteUnit'
export const updateUnitField = 'updateUnitField'
export const updatePdf = 'updatePdf'
export const addUnitUser = 'addUnitUser'
export const removeUnitUser = 'removeUnitUser'

export const fetchUnitImages = 'fetchUnitImages'
export const updateUnitImages = 'updateUnitImages'

export const state = () => ({
  [units]: undefined,
  [unit]: undefined,
  [unitPlayerdata]: undefined,
  [loading]: false,
  [pdfLoading]: false,
  [error]: undefined,
  [unitImages]: undefined,
  [unitImagesLoading]: false,
})

export const getters = {
  ...simpleGetters(state),
}

export const mutations = {
  ...simpleMutations(state),
}

export const actions = {
  [fetchUnits](storeBag, { slug }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: units,
      fetch: () => this.$axios.get(`${API_URL}/projects/${slug}/units`),
    })
  },
  [fetchUnit](storeBag, { id }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: unit,
      fetch: () => this.$axios.get(`${API_URL}/units/${id}`),
    })
  },
  [fetchUnitPlayerdata](storeBag, { project, unitId }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: unitPlayerdata,
      fetch: () => this.$axios.get(`${API_URL}/playerdata/${project}/${unitId}`),
    })
  },
  [createUnit](storeBag, { payload }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: unit,
      successMsg: this.$i18n.t('created-unit'),
      failureMsg: this.$i18n.t('could-not-create-unit'),
      fetch: () => this.$axios.post(`${API_URL}/units`, payload),
    })
  },
  [updateUnit](storeBag, { id, payload, noNotification }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: unit,
      successMsg: noNotification ? undefined : this.$i18n.t('updated-unit'),
      failureMsg: this.$i18n.t('could-not-update-unit'),
      fetch: () => this.$axios.put(`${API_URL}/units/${id}`, payload),
    })
  },
  [deleteUnit](storeBag, { id }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      successMsg: this.$i18n.t('deleted-unit'),
      failureMsg: this.$i18n.t('could-not-delete-unit'),
      fetch: () => this.$axios.delete(`${API_URL}/units/${id}`),
    })
  },
  [updateUnitField](storeBag, { id, key, value, slug }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      fetch: () => this.$axios.put(`${API_URL}/projects/${slug}/unit/${id}/field`, { key, value }),
      successMsg: this.$i18n.t('unit-field-updated'),
      errorMsg: this.$i18n.t('could-not-update-unit-field'),
    })
  },
  [updatePdf](storeBag, { id, pdf, onUploadProgress, noNotification }) {
    return actionCreator({
      storeBag,
      loading: pdfLoading,
      error,
      commit: unit,
      successMsg: noNotification ? undefined : this.$i18n.t('unit-pdf-updated'),
      errorMsg: this.$i18n.t('could-not-update-unit-pdf'),
      fetch: () => {
        const formData = new FormData()
        formData.append('file', pdf)
        return this.$axios.post(`${API_URL}/units/${id}/pdf`, formData, { onUploadProgress })
      },
    })
  },
  [fetchUnitImages](storeBag, { id }) {
    return actionCreator({
      storeBag,
      loading: unitImagesLoading,
      error,
      commit: unitImages,
      fetch: () => this.$axios.get(`${API_URL}/units/${id}/images`),
    })
  },
  [updateUnitImages](storeBag, { id, payload, noNotification }) {
    return actionCreator({
      storeBag,
      loading: unitImagesLoading,
      error,
      commit: unitImages,
      successMsg: noNotification ? undefined : this.$i18n.t('updated-unit'),
      failureMsg: this.$i18n.t('could-not-update-unit'),
      fetch: () => this.$axios.put(`${API_URL}/units/${id}/images`, payload),
    })
  },
  [addUnitUser](storeBag, { id, email }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      successMsg: this.$i18n.t('added-unit-user'),
      errorMsg: this.$i18n.t('could-not-add-unit-user-make-sure-the-email-address-is-valid'),
      fetch: () => this.$axios.put(`${API_URL}/units/${id}/user`, { email }),
    })
  },
  [removeUnitUser](storeBag, { id, email }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      successMsg: this.$i18n.t('removed-unit-user'),
      errorMsg: this.$i18n.t('could-not-remove-unit-user'),
      fetch: () => this.$axios.post(`${API_URL}/units/${id}/user`, { email }),
    })
  },
}
