import { simpleGetters, simpleMutations, actionCreator } from '@qispace/vue-components'
import { fetchProjects, projects } from './projects'
import { fetchClients, clients } from './clients'

export const global = 'global'
export const clientContext = 'clientContext'
export const projectContext = 'projectContext'
export const initialized = 'initialized'
export const loading = 'loading'
export const error = 'error'

export const setClientContext = 'setClientContext'
export const setProjectContext = 'setProjectContext'
export const unsetProjectContext = 'unsetProjectContext'
export const initializeGlobalStore = 'initializeGlobalStore'

export const state = () => ({
  [clientContext]: JSON.parse(localStorage.getItem('clientContext')),
  [projectContext]: JSON.parse(localStorage.getItem('projectContext')),
  [initialized]: false,
  [loading]: false,
  [error]: undefined,
})

export const getters = {
  ...simpleGetters(state),
}

export const mutations = {
  ...simpleMutations(state),
  [setClientContext](state, client) {
    state.clientContext = client
    localStorage.clientContext = JSON.stringify(state.clientContext)
  },
  [setProjectContext](state, project) {
    state.projectContext = project
    localStorage.projectContext = JSON.stringify(state.projectContext)
  },
  [unsetProjectContext](state) {
    state.projectContext = undefined
    localStorage.removeItem(projectContext)
  },
}

export const actions = {
  [setClientContext](storeBag, clientId) {
    return actionCreator({
      storeBag,
      loading,
      error,
      action: async () => {
        const { state, dispatch } = storeBag
        const cs = await dispatch(`${clients}/${fetchClients}`, {}, { root: true })
        const clientContext = cs.find((c) => c.id === clientId)
        if (!clientContext) {
          throw new Error('client does not exists')
        }

        let projectContext
        if (!state.projectContext || state.projectContext.clientId !== clientId) {
          const ps = await dispatch(
            `${projects}/${fetchProjects}`,
            { slug: clientContext.slug },
            { root: true }
          )
          projectContext = ps[0]
        }

        return {
          projectContext,
          clientContext,
        }
      },
      commit: ({ clientContext, projectContext }) => {
        return {
          [setClientContext]: clientContext,
          [setProjectContext]: projectContext,
        }
      },
    })
  },
  [setProjectContext](storeBag, projectId) {
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: setProjectContext,
      action: async () => {
        const { state, dispatch } = storeBag
        if (!state.clientContext) {
          throw new Error('need client context to set project context')
        }

        const ps = await dispatch(
          `${projects}/${fetchProjects}`,
          { slug: state.clientContext.slug },
          { root: true }
        )
        const project = ps.find((c) => c.id === projectId)
        if (!project) {
          throw new Error('project does not exists')
        }

        return project
      },
    })
  },
  [unsetProjectContext]({ commit }) {
    commit(unsetProjectContext)
  },
  [initializeGlobalStore](storeBag) {
    return actionCreator({
      storeBag,
      loading,
      error,
      action: async () => {
        const { state, dispatch } = storeBag
        const cs = await dispatch(`${clients}/${fetchClients}`, {}, { root: true })
        if (!state.clientContext || !cs.map((c) => c.id).includes(state.clientContext.id)) {
          const ps = await dispatch(
            `${projects}/${fetchProjects}`,
            { slug: cs[0].slug },
            { root: true }
          )
          return { clientContext: cs[0], projectContext: ps[0] }
        } else {
          const ps = await dispatch(
            `${projects}/${fetchProjects}`,
            { slug: state.clientContext.slug },
            { root: true }
          )
          if (!state.projectContext || !ps.map((p) => p.id).includes(state.projectContext.id)) {
            return { projectContext: ps[0] }
          } else {
            return {}
          }
        }
      },
      commit: ({ clientContext, projectContext }) => {
        return {
          [setClientContext]: clientContext,
          [setProjectContext]: projectContext,
          [initialized]: true,
        }
      },
    })
  },
}
