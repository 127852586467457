import { simpleGetters, simpleMutations, actionCreator } from '@qispace/vue-components'
import { API_URL } from '~/constants/env'

export const projectMetrics = 'projectMetrics'
export const slug = 'slug'
export const loading = 'loading'
export const error = 'error'

export const getProjectMetrics = 'getProjectMetrics'

export const state = () => ({
  [projectMetrics]: undefined,
  [slug]: undefined,
  [loading]: false,
  [error]: undefined,
})

export const getters = {
  ...simpleGetters(state),
}

export const mutations = {
  ...simpleMutations(state),
}

export const actions = {
  [getProjectMetrics](storeBag, { slug: s }) {
    storeBag.commit(slug, s)
    return actionCreator({
      storeBag,
      loading,
      error,
      commit: projectMetrics,
      fetch: () => this.$axios.get(`${API_URL}/projects/metrics/client/${s}`),
    })
  },
}
