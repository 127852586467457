import { simpleGetters, simpleMutations } from '@qispace/vue-components'

export const message = 'message'
export const showMessage = 'showMessage'
export const expandNavPanel = 'expandNavPanel'
export const redirected = 'redirected'

export const showNavPanel = 'showNavPanel'
export const hideNavPanel = 'hideNavPanel'
export const setExpandNavPanel = 'setExpandNavPanel'
export const toggleExpandNavPanel = 'toggleExpandNavPanel'
export const setRedirected = 'setRedirected'

export const state = () => ({
  message: {
    content: '',
    color: '',
  },
  showNavPanel: false,
  expandNavPanel: false,
  redirected: false,
})

export const getters = {
  ...simpleGetters(state),
}

export const mutations = {
  ...simpleMutations(state),
  [toggleExpandNavPanel](state) {
    state.expandNavPanel = !state.expandNavPanel
  },
  [showNavPanel](state) {
    state.showNavPanel = true
  },
  [hideNavPanel](state) {
    state.showNavPanel = false
  },
}

export const actions = {
  [showMessage]({ commit }, payload) {
    commit(message, payload)
  },
  setExpandNavPanel({ commit }, payload) {
    commit(expandNavPanel, payload)
  },
  showNavPanel({ commit }) {
    commit(showNavPanel)
  },
  hideNavPanel({ commit }) {
    commit(hideNavPanel)
  },
  toggleExpandNavPanel({ commit }) {
    commit(toggleExpandNavPanel)
  },
  setRedirected({ commit }, payload) {
    commit(redirected, payload)
  },
}
