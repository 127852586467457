import { simpleGetters, simpleMutations, actionCreator } from '@qispace/vue-components'
import { API_URL } from '~/constants/env'

export const statuses = 'statuses'
export const loading = 'loading'
export const error = 'error'

export const createStatus = 'createStatus'
export const updateStatus = 'updateStatus'
export const deleteStatus = 'deleteStatus'

export const state = () => ({
  [loading]: false,
  [error]: undefined,
})

export const getters = {
  ...simpleGetters(state),
}

export const mutations = {
  ...simpleMutations(state),
}

export const actions = {
  [createStatus](storeBag, { slug, payload }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      successMsg: this.$i18n.t('created-status'),
      failureMsg: this.$i18n.t('could-not-create-status'),
      fetch: () => this.$axios.post(`${API_URL}/projects/${slug}/statuses`, payload),
    })
  },
  [updateStatus](storeBag, { slug, id, payload }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      successMsg: this.$i18n.t('updated-status'),
      failureMsg: this.$i18n.t('could-not-update-status'),
      fetch: () => this.$axios.put(`${API_URL}/projects/${slug}/statuses/${id}`, payload),
    })
  },
  [deleteStatus](storeBag, { slug, id }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      successMsg: this.$i18n.t('deleted-status'),
      failureMsg: this.$i18n.t('could-not-delete-status'),
      fetch: () => this.$axios.delete(`${API_URL}/projects/${slug}/statuses/${id}`),
    })
  },
}
