
import Vue from 'vue'

export default {
  data() {
    return {
      isAnythingLoading: false,
      defaultNuxtLoader: false,
    }
  },
  watch: {
    isAnythingLoading() {
      if (this.defaultNuxtLoader) {
        if (this.isAnythingLoading) {
          this.$nuxt.$loading.start()
        } else {
          this.$nuxt.$loading.finish()
        }
      }
      Vue.qiSpaceComponentsSettings().observable.isAnythingLoading = this.isAnythingLoading
    },
  },
  created() {
    this.$store.subscribe((_mutation, state) => {
      this.isAnythingLoading =
        state.global.loading ||
        state.analytics.loading ||
        state.buildings.loading ||
        state.clients.loading ||
        state.contacts.loading ||
        state.fields.loading ||
        state.ifcUploads.loading ||
        state.images.loading ||
        state.imports.loading ||
        state.renderData.loading ||
        state.projects.loading ||
        state.units.loading ||
        state.units.pdfLoading ||
        state.statuses.loading ||
        state.unitVersions.loading ||
        state.users.loading
    })
  },
}
