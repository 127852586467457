
export default {
  computed: {
    environmentColor() {
      if (window.location.hostname === 'localhost') {
        return 'error'
      } else if (window.location.host === 'admin.qistage.com') {
        return 'blue'
      } else if (window.location.host === 'dev-admin.qistage.com') {
        return 'orange'
      } else {
        return 'primary'
      }
    },
    environmentHighlightColor() {
      if (window.location.hostname === 'localhost') {
        return 'white'
      } else if (window.location.host === 'admin.qistage.com') {
        return 'white'
      } else if (window.location.host === 'dev-admin.qistage.com') {
        return 'white'
      } else {
        return 'white'
      }
    },
  },
}
