import { simpleGetters, simpleMutations, actionCreator } from '@qispace/vue-components'
import { API_URL } from '~/constants/env'

export const images = 'images'
export const loading = 'loading'
export const error = 'error'

export const updateImages = 'updateImages'
export const deleteImage = 'deleteImage'
export const moveImage = 'moveImage'

export const state = () => ({
  [loading]: false,
  [error]: undefined,
})

export const getters = {
  ...simpleGetters(state),
}

export const mutations = {
  ...simpleMutations(state),
}

export const actions = {
  [updateImages](storeBag, { slug, payload }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      fetch: () => this.$axios.put(`${API_URL}/projects/${slug}/images`, payload),
    })
  },
  [deleteImage](storeBag, { slug, id }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      successMsg: this.$i18n.t('project-images-updated'),
      failureMsg: this.$i18n.t('could-not-update-project-images'),
      fetch: () => this.$axios.delete(`${API_URL}/projects/${slug}/images/${id}`),
    })
  },
  [moveImage](storeBag, { slug, id, up }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      successMsg: this.$i18n.t('project-images-updated'),
      failureMsg: this.$i18n.t('could-not-update-project-images'),
      fetch: () =>
        this.$axios.get(`${API_URL}/projects/${slug}/images/${id}/order/${up ? 'up' : 'down'}`),
    })
  },
}
