//
//
//
//
//
//
//
//
//
//
//

import { message } from '~/store'

export default {
  data() {
    return {
      show: false,
      message: '',
      color: '',
    }
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === message) {
        this.message = state.message.content
        this.color = state.message.color
        this.show = true
      }
    })
  },
}
