import Keycloak from 'keycloak-js'
import { scope } from './authorization/scopes'
import { allRoles } from './authorization/roles'

export default async (context, inject) => {
  let scopesList = ''
  Object.values(scope).forEach((val, key) => {
    scopesList += val + ' '
  })

  const authSettings = {
    realm: process.env.AUTH_REALM,
    url: process.env.AUTH_API_URL,
    clientId: 'qi-admin',
    'public-client': true,
    'confidential-port': 0,
  }

  const keycloak = new Keycloak(authSettings)

  keycloak.onReady = function () {
    // console.log('Ready!!!')
  }

  keycloak.onAuthSuccess = function () {
    // console.log('Successfully authenticted')
  }

  const getRoleName = function (decodedToken) {
    if (!decodedToken.realm_access) return ''
    if (!decodedToken.realm_access.roles) return ''

    let roles = decodedToken.realm_access.roles
    roles = roles.filter((item) => allRoles.includes(item))
    if (roles.length) return roles[0]
    return ''
  }

  keycloak.getRole = function () {
    return getRoleName(this.tokenParsed)
  }

  keycloak.getScopes = function () {
    return this.tokenParsed.scope.split(' ')
  }

  keycloak.can = function (scope) {
    return this.getScopes().includes(scope)
  }

  keycloak.is = function (role) {
    return role === this.getRole()
  }

  const callLogout = () => {
    localStorage.removeItem('clientContext')
    localStorage.removeItem('projectContext')
  }

  keycloak.onAuthLogout = function () {
    // console.log('onAuthLogout -Logging out')
    callLogout()
  }

  keycloak.onTokenExpired = async function () {
    // console.log('Token has expired')
    await keycloak.updateToken(5)
  }

  keycloak.onAuthRefreshError = function () {
    console.log('Failed to refresh token')
    callLogout()
  }

  keycloak.onAuthRefreshSuccess = function () {
    // console.log('Token refreshed')
  }

  await keycloak.init({
    onLoad: 'login-required',
    redirectUri: location.href,
    scope: scopesList,
    checkLoginIframe: false,
  })

  if (!keycloak.authenticated) {
    callLogout()
    await keycloak.login({ scope: scopesList })
  } else {
    if (process.env.NODE_ENV === 'development') {
      console.log(keycloak)
    }
    inject('keycloak', keycloak)
  }
}
