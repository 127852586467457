//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { global, loading, clientContext, projectContext, initialized } from '~/store/global'
import app from '~/mixins/app'

export default {
  mixins: [app],
  props: {
    outlined: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapGetters(global, [loading, clientContext, projectContext, initialized]),
  },
}
