//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TheAppMenu',
  computed: {
    email() {
      return this.$keycloak.idTokenParsed.email
    },
    accountUrl() {
      return this.$keycloak.idTokenParsed.iss + '/account'
    },
  },
  methods: {
    logout() {
      this.$keycloak.logout()
    },
  },
}
