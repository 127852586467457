import {
  objectToQueryParameters,
  simpleGetters,
  simpleMutations,
  actionCreator,
} from '@qispace/vue-components'
import { API_URL } from '~/constants/env'

export const ifcUploads = 'ifcUploads'
export const loading = 'loading'
export const error = 'error'

export const fetchIfcUploads = 'fetchIfcUploads'
export const uploadIfc = 'uploadIfc'
export const promoteToProduction = 'promoteToProduction'
export const promoteToProductionLoading = 'promoteToProductionLoading'

export const state = () => ({
  ifcUploads: undefined,
  loading: false,
  error: undefined,
  promoteToProductionLoading: false,
})

export const getters = {
  ...simpleGetters(state),
}

export const mutations = {
  ...simpleMutations(state),
}

export const actions = {
  [fetchIfcUploads](storeBag, queryParameters) {
    return actionCreator({
      storeBag,
      loading,
      error,
      state: ifcUploads,
      fetch: () => this.$axios.get(`${API_URL}/ifc?${objectToQueryParameters(queryParameters)}`),
    })
  },
  [uploadIfc](storeBag, { onUploadProgress, project, file }) {
    return actionCreator({
      storeBag,
      loading,
      error,
      fetch: () => {
        const payload = new FormData()
        payload.append('file', file)
        payload.append('project', project)
        return this.$axios.post(`${API_URL}/admin/${project}/ifc`, payload, {
          onUploadProgress,
        })
      },
    })
  },
  [promoteToProduction](storeBag, { project, payload }) {
    return actionCreator({
      storeBag,
      loading: promoteToProductionLoading,
      error,
      fetch: () => this.$axios.post(`${API_URL}/admin/${project}/ifc:promote`, payload),
      successMsg: this.$i18n.t('all-done'),
      errorMsg: this.$i18n.t('error'),
    })
  },
}
